<template>
    <b-container v-if="shop">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ shop.id ? '修改分店' : '建立分店' }}</h3>
            </b-col>
            <b-col v-if="shop.id">
                <b-button class="float-right" variant="primary" :href="`http://togo.nube.hk/${shop.id}/home`">網上點餐連結</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="shop.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="地址">
                <b-form-input v-model="shop.address"></b-form-input>
            </b-form-group>
            <b-form-group label="電話號碼">
                <b-form-input v-model="shop.phone"></b-form-input>
            </b-form-group>

            <h5>分類</h5>
            <b-form-group label="可選分類">
                <n-multiselect label="name" :options="categories" :reset-after="true" @select="addCategory"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="shop.categories.length > 0">
                <n-draggable class="list-group" :list="shop.categories">
                    <b-list-group-item v-for="(category, index) in shop.categories" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ category.name }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeCategory(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </n-draggable>
            </b-form-group>

            <h5>營業時間</h5>
            <b-form-group v-for="(openingHour, index) in shop.openingHours" :key="index" :label="weekdayLabels[index]">
                <b-row>
                    <b-col class="mt-2" md="6">
                        <b-input-group prepend="開店時間">
                            <b-form-input v-model="openingHour[0].start"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col class="mt-2" md="6">
                        <b-input-group prepend="關店時間">
                            <b-form-input v-model="openingHour[0].end"></b-form-input>
                        </b-input-group>
                    </b-col>
                </b-row> 
            </b-form-group>

            <h5>圖片</h5>
            <b-form-group label="上傳圖片">
                <b-form-file v-model="newImage" accept="image/jpeg, image/png" />
                <b-card v-if="shop.image" class="mt-3" :img-src="shop.image">
                    <b-button variant="danger" block @click="removeImage()">刪除</b-button>
                </b-card>
            </b-form-group>

            <h5>訂單方式</h5>
            <b-form-group>
                <n-multiselect label="text" :options="orderMethods" :reset-after="true" @select="addOrderMethod"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="shop.orderMethods.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(method, index) in shopOrderMethods" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ method.text }}</b-col>
                            <b-col cols="4">
                                <b-button variant="danger" block @click="removeOrderMethod(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-form-group>

            <h5>付款方式</h5>
            <b-form-group>
                <n-multiselect label="text" :options="paymentMethods" :reset-after="true" @select="addPaymentMethod"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="shop.paymentMethods.length > 0">
                <b-list-group>
                    <b-list-group-item v-for="(method, index) in shopPaymentMethods" :key="index">
                        <b-row>
                            <b-col class="pt-2">{{ method.text }}</b-col>
                            <b-col v-if="method.value != 'CASH'" cols="4">
                                <b-button variant="danger" block @click="removePaymentMethod(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </b-list-group>
            </b-form-group>

            <h5>RECON網上付款</h5>
            <b-form-group>
                <b-form-checkbox v-model="shop.hasRecon" switch>{{ shop.hasRecon ? '已啟用' : '已停用' }}</b-form-checkbox>
            </b-form-group>
            <b-form-group v-if="shop.hasRecon" label="商戶編號">
                <b-form-input v-model="shop.reconCode"></b-form-input>
            </b-form-group>

             <h5>PAYME網上付款</h5>
            <b-form-group>
                <b-form-checkbox v-model="shop.hasPayme" switch>{{ shop.hasPayme ? '已啟用' : '已停用' }}</b-form-checkbox>
            </b-form-group>

            <h5>PAYME CODE</h5>
            <b-form-group label="上傳PAYME CODE">
                <b-form-file v-model="imagePayme" accept="image/jpeg, image/png" />
                <b-card v-if="shop.imagePayme" class="mt-3" :img-src="shop.imagePayme">
                    <b-button variant="danger" block @click="removeImage()">刪除</b-button>
                </b-card>
            </b-form-group>

             <h5>轉數快網上付款</h5>
            <b-form-group>
                <b-form-checkbox v-model="shop.hasFPS" switch>{{ shop.hasFPS ? '已啟用' : '已停用' }}</b-form-checkbox>
            </b-form-group>

            <h5>轉數快</h5>
            <b-form-group label="上傳轉數快 CODE">
                <b-form-file v-model="imageFPS" accept="image/jpeg, image/png" />
                <b-card v-if="shop.imageFPS" class="mt-3" :img-src="shop.imageFPS">
                    <b-button variant="danger" block @click="removeImage()">刪除</b-button>
                </b-card>
            </b-form-group>

            <h5>準備及預約</h5>
            <b-form-group label="預約日數">
                <b-input-group append="日">
                    <b-form-input v-model.number="shop.reservationDays" type="number" step="1" required></b-form-input>
                </b-input-group>
            </b-form-group>
            <b-form-group label="準備時間">
                <b-input-group append="分鐘">
                    <b-form-input v-model.number="shop.readyTime" type="number" step="1" required></b-form-input>
                </b-input-group>
            </b-form-group>
            <b-form-group label="繁忙準備時間">
                <b-input-group append="分鐘">
                    <b-form-input v-model.number="shop.busyTime" type="number" step="1" required></b-form-input>
                </b-input-group>
            </b-form-group>
            <b-form-group label="預訂間距">
                <b-input-group append="分鐘">
                    <b-form-input v-model.number="shop.timeInterval" type="number" step="1" required></b-form-input>
                </b-input-group>
            </b-form-group>

            <h5>社交媒體</h5>
            <b-form-group label="Facebook">
                <b-form-input v-model="shop.facebookUrl"></b-form-input>
            </b-form-group>
            <b-form-group label="Instagram">
                <b-form-input v-model="shop.instagramUsername"></b-form-input>
            </b-form-group>
            
            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Shop } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            shop: null,
            newImage: null,
            imagePayme: null,
            imageFPS: null,
            weekdayLabels: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'],
            orderMethods: [
                { value: 'PICKUP', text: '自取' },
                { value: 'DELIVERY', text: '外送' },
            ],
            paymentMethods: [
                { value: 'CASH', text: '現金' },
                { value: 'OCTOPUS', text: '八達通' },
                { value: 'VISA', text: 'Visa' },
                { value: 'MASTER', text: 'Mastercard' },
                { value: 'UPOP', text: '銀聯' },
                { value: 'AE', text: 'American Express' },
                { value: 'JCB', text: 'JCB' },
                { value: 'WECHATPAY', text: '微信支付' },
                { value: 'ALIPAY', text: '支付寶' },
                { value: 'PAYME', text: 'PayMe' },
                { value: 'TAPNGO', text: 'Tap & Go' },
                { value: 'FPS', text: '轉數快' },
                { value: 'RECON_VISA', text: 'RECON Visa' },
                { value: 'RECON_MASTER', text: 'RECON Master' },
                { value: 'RECON_UPOP', text: 'RECON 銀聯' },
                { value: 'ON_THE_HOUSE', text: 'One The House' },
                { value: 'DELIVEROO', text: '戶戶送' },
                { value: 'FOODPANDA', text: 'foodpanda' },
            ]
        }
    },

    async created() {
        this.shop = this.action == 'create' ? Shop() : await this.$store.dispatch('getDocument', { col: 'shops', id: this.$route.params.id })
        if (!this.shop) return this.$router.push('/shops')
        await this.$store.dispatch('bindCollection', { col: 'categories' })
    },

    computed: {
        ...mapState({
            categories: (state) => state.categories,
        }),

        shopOrderMethods() {
            let ret = []
            this.shop.orderMethods.forEach(value => [
                ret.push(this.orderMethods.find(method => method.value == value))
            ])
            return ret
        },

        shopPaymentMethods() {
            let ret = []
            this.shop.paymentMethods.forEach(value => {
                ret.push(this.paymentMethods.find(method => method.value == value))
            })
            return ret
        }
    },

    methods: {
        addCategory(obj) {
            if (!this.shop.categories.find((category) => category.id == obj.id)) return this.shop.categories.push(Object.assign({}, obj))
        },

        async removeCategory(index) {
            try{
                this.shop.categories.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'shops', doc: this.shop })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        addOrderMethod(obj) {
            if (!this.shop.orderMethods.find(value => value == obj.value)) return this.shop.orderMethods.push(obj.value)
        },

        addPaymentMethod(obj) {
            if (!this.shop.paymentMethods.find(value => value == obj.value)) return this.shop.paymentMethods.push(obj.value)
        },

        async removeOrderMethod(index) {
            try{
                this.shop.paymentMethods.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'shops', doc: this.shop })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        async onSubmit() {
            this.shop.merchantId = localStorage.merchantId
            this.shop.merchantName = localStorage.merchantName

            let res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'shops', doc: this.shop })

            this.shop.id = res.id
            if (this.newImage) {
                this.shop.image = await this.$store.dispatch('createStorage', { col: 'shops', id: this.shop.id, file: this.newImage })
                res = await this.$store.dispatch('updateDocument', { col: 'shops', doc: this.shop })
            }
            if (this.imagePayme) {
                this.shop.imagePayme = await this.$store.dispatch('createStorage', { col: 'shops', id: this.shop.id + '_payme', file: this.imagePayme })
                res = await this.$store.dispatch('updateDocument', { col: 'shops', doc: this.shop })
            }
             if (this.imageFPS) {
                this.shop.imageFPS = await this.$store.dispatch('createStorage', { col: 'shops', id: this.shop.id + '_FPS', file: this.imageFPS })
                res = await this.$store.dispatch('updateDocument', { col: 'shops', doc: this.shop })
            }
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/shops')
        },
    },
}
</script>
